// CSS Variables
// See `_breakpoints.scss` for media query breakpoint variables.

:root {
	// Color Definitions.
	--wpe--color--black: #010101;
	--wpe--color--white: #fff;
	--wpe--color--light-gray: #EBEBEB;
	--wpe--color--gray: #DDDDDD;
	--wpe--color--dark-gray: #9A9A9A;
	--wpe--color--very-dark-gray: #181818;
	--wpe--color--inu-orange: #ED553B;
	--wpe--color--pug-yellow: #F9B342;
	--wpe--color--mongrel-brown: #5B2B2F;
	--wpe--color--prusian-blue: #033243;
	--wpe--color--aquamarine: #1F8C8D;
	--wpe--color--spring-green: #3CB4A0;
	--wpe--color--error: #B31412;
	--wpe--color--success: #6DD40;

	// Color Assignments.
	--wpe--color--accent: var(--wpe--color--inu-orange);
	--wpe--color--accent--dark: var(--wpe--color--mongrel-brown);
	--wpe--color--title: var(--wpe--color--black);
	--wpe--link--color: var(--wpe--color--aquamarine);
	--wpe--code--color: var(--wpe--color--aquamarine);
	--wpe--code--background-color: var(--wpe--color--white);
	--wpe--header--color: var(--wpe--color--black);
	--wpe--header--background-color: var(--wpe--color--white);
	--wpe--navigation-menu--color: var(--wpe--color--inu-orange);
	--wpe--navigation-menu--color--hover: var(--wpe--color--mongrel-brown);
	--wpe--navigation-menu--background-color: transparent;
	--wpe--dropdown--background-color: var(--wpe--color--white);
	--wpe--main--color: var(--wpe--color--black);
	--wpe--main--background-color: var(--wpe--color--white);
	--wpe--footer--color: var(--wpe--color--white);
	--wpe--footer--background-color: var(--wpe--color--spring-green);
	--wpe--heading--color: var(--wpe--color--black);
	--wpe--border--light: 1px solid var(--wpe--color--light-gray);
	--wpe--border--extra-light: 1px solid var(--wpe--color--light-gray);

	// Type.
	--wpe--font-weight--light: 300;
	--wpe--font-weight--regular: 400;
	--wpe--font-weight--medium: 500;
	--wpe--font-weight--bold: 700;

	// Spacing.
	--wpe--content--max-width: 620px;
	--wpe--section--padding-y: 3rem;
	--wpe--container--padding-x: 15px;
	--wpe--container--max-width: 1000px;

	// Shadows.
	--wpe--shadow--large: 0 50px 120px -40px rgba(150, 150, 150, 0.2), 0 20px 60px -30px rgba(0, 0, 0, 0.5);
}
