// Global styles
// These styles are available on all pages.
// If a component has it's own styles, they can be found within the component's respective directory.

@import "breakpoints";
@import "css-variables";
@import "base";
@import "utilities";

* {
    text-decoration: none !important;
}

.postContent {
    img {
        width: 100% !important;
    }
}